export interface ManagementState {
  isSchedulingPensions: boolean;
  isSchedulingRebate: boolean;
  isSchedulingReinsuranceEventFieldSync: boolean;
}

export const initialState: ManagementState = {
  isSchedulingPensions: false,
  isSchedulingRebate: false,
  isSchedulingReinsuranceEventFieldSync: false,
};
