import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { Logger } from './core';
import { AppInsightsService } from './core/services/app-insights.service';
import { PwaService } from './core/services/pwa.service';
import { AppConfigService } from './core/services/app-config/app-config.service';
import { AuthenticationService } from './authentication/services/authentication.service';
const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = this.appConfig.config.title;

  constructor(
    private appInsights: AppInsightsService,
    private updates: PwaService,
    private titleService: Title,
    private config: NgbTooltipConfig,
    private appConfig: AppConfigService,
    private authService: AuthenticationService,
    private msalService: MsalService,
  ) {
    config.animation = true;
    config.tooltipClass = 'fem-tooltip';
    authService.registerBroadcastService();
  }

  ngOnInit() {
    this.msalService.handleRedirectObservable().subscribe();

    this.authService.subscribeToUserDetails();
    this.authService.registerBroadcastService();
    this.authService.checkIsLoggedInToValidAccount();
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.appInsights.logTrace('init');
    this.updates.registerUpdateHandlers();
    this.titleService.setTitle(this.title);
  }
}
