import { Action, createReducer, on } from '@ngrx/store';
import {
  initialState,
  ManagementState,
} from '@app/management/state/management.state';
import * as actions from '../state/management.actions';

const reducer = createReducer<ManagementState, Action>(
  initialState,

  on(actions.schedulePensionJob, (state) => ({
    ...state,
    isSchedulingPensions: true,
  })),
  on(
    actions.schedulePensionJobSuccess,
    actions.schedulePensionJobFailure,
    (state) => ({
      ...state,
      isSchedulingPensions: false,
    }),
  ),

  on(actions.scheduleRebateCalculation, (state) => ({
    ...state,
    isSchedulingRebate: true,
  })),
  on(
    actions.scheduleRebateCalculationSuccess,
    actions.scheduleRebateCalculationFailure,
    (state) => ({
      ...state,
      isSchedulingRebate: false,
    }),
  ),

  on(actions.scheduleReinsuranceEventFieldSync, (state) => ({
    ...state,
    isSchedulingReinsuranceEventFieldSync: true,
  })),
  on(
    actions.scheduleReinsuranceEventFieldSyncSuccess,
    actions.scheduleReinsuranceEventFieldSyncFailure,
    (state) => ({
      ...state,
      isSchedulingReinsuranceEventFieldSync: false,
    }),
  ),
);

export const managementReducer = (state: ManagementState, action: Action) =>
  reducer(state, action);
