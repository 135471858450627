{
  "name": "fem-frontend",
  "version": "6.10.0-0",
  "scripts": {
    "ng": "ng",
    "start": "node --max_old_space_size=5120 node_modules/@angular/cli/bin/ng serve",
    "version": "ng v",
    "build": "ng build",
    "build-dev": "npm run beefybuild -- --configuration=dev --verbose",
    "build-uat": "npm run beefybuild -- --configuration=uat",
    "build-prod": "npm run beefybuild -- --configuration=production",
    "build-ci": "npm run beefybuild -- --configuration=ci --source-map=false",
    "analyse-dev": "npm run build-dev -- --stats-json && webpack-bundle-analyzer ./dist/fem-frontend/stats.json",
    "analyse-uat": "npm run build-uat -- --stats-json && webpack-bundle-analyzer ./dist/fem-frontend/stats.json",
    "beefybuild": "node --max_old_space_size=5120 node_modules/@angular/cli/bin/ng build ",
    "test": "ng t",
    "beefylint": "node --max_old_space_size=5120 node_modules/@angular/cli/bin/ng lint",
    "lint": "npm run beefylint",
    "lint:fix": "npm run beefylint -- --fix",
    "lint:watch": "nodemon --exec \"npm run lint || exit 1\" --ext ts,html,scss",
    "e2e": "ng e2e",
    "prettier": "prettier --write \"./{src,e2e}/**/*.{ts,js,html,sass}\"",
    "prettier:check": "prettier --list-different \"./{src,e2e}/**/*.{ts,js,html,sass}\"",
    "generate": "ng generate",
    "compodoc": "npx compodoc -p tsconfig.doc.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.0.5",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/forms": "^17.3.4",
    "@angular/google-maps": "^17.0.5",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@angular/service-worker": "^17.3.4",
    "@azure/msal-angular": "^3.0.10",
    "@ckeditor/ckeditor5-angular": "^7.0.1",
    "@ckeditor/ckeditor5-build-classic": "^41.0.0",
    "@ckeditor/ckeditor5-core": "^41.0.0",
    "@ckeditor/ckeditor5-engine": "^41.0.0",
    "@ckeditor/ckeditor5-utils": "^41.0.0",
    "@ckeditor/ckeditor5-watchdog": "^41.0.0",
    "@compodoc/compodoc": "^1.1.22",
    "@microsoft/applicationinsights-web": "^2.4.4",
    "@microsoft/signalr": "^8.0.7",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-idle/core": "^14.0.0",
    "@ng-idle/keepalive": "^14.0.0",
    "@ngrx/effects": "^17.1.1",
    "@ngrx/router-store": "^17.1.1",
    "@ngrx/store": "^17.1.1",
    "@ngrx/store-devtools": "^17.1.1",
    "@perfectmemory/ngx-contextmenu": "^17.0.0",
    "@popperjs/core": "^2.11.8",
    "@swimlane/ngx-charts": "^20.5.0",
    "@swimlane/ngx-datatable": "20.1",
    "@types/qs": "^6.9.0",
    "@types/ramda": "^0.27.34",
    "bootstrap": "5.3",
    "d3-selection": "^3.0.0",
    "dwt": "^17.2.5",
    "file-saver": "^2.0.5",
    "google-libphonenumber": "^3.2.33",
    "jwt-decode": "^2.2.0",
    "lodash": "^4.17.20",
    "moment": "^2.24.0",
    "ng-speed-test": "2.6",
    "ng2-file-upload": "^5.0.0",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-markdown": "^17.1.1",
    "npm": "^10.3.0",
    "qs": "^6.9.1",
    "ramda": "^0.27.1",
    "rxjs": "^7.8.1",
    "rxjs-compat": "^6.6.3",
    "south-african-id-parser": "^1.0.0",
    "tslib": "^2.6.2",
    "webpack-sources": "^3.2.3",
    "xlsx": "^0.18.5",
    "zone.js": "^0.14.3"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.0",
    "@angular-devkit/build-angular": "^17.3.3",
    "@angular-eslint/builder": "^17.2.0",
    "@angular-eslint/eslint-plugin": "^17.2.0",
    "@angular-eslint/eslint-plugin-template": "^17.2.0",
    "@angular-eslint/schematics": "^17.2.0",
    "@angular-eslint/template-parser": "^17.2.0",
    "@angular/cli": "^17.3.3",
    "@angular/compiler-cli": "^17.3.4",
    "@angular/language-service": "^17.3.4",
    "@angular/localize": "^17.3.4",
    "@babel/core": "^7.6.4",
    "@types/d3-scale": "^4.0.2",
    "@types/d3-selection": "^3.0.2",
    "@types/d3-shape": "^3.1.6",
    "@types/file-saver": "^2.0.1",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jest": "^29.5.11",
    "@types/jwt-decode": "^2.2.1",
    "@types/lodash": "^4.14.157",
    "@types/node": "^12.11.1",
    "@typescript-eslint/eslint-plugin": "^6.19.1",
    "@typescript-eslint/parser": "^6.19.1",
    "@typescript-eslint/types": "^6.19.1",
    "babel-loader": "^8.0.6",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-prefer-arrow-functions": "^3.2.4",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-unused-imports": "^3.0.0",
    "jasmine-core": "~3.6.0",
    "jasmine-spec-reporter": "~5.0.0",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.0.0",
    "ng-mocks": "^14.12.1",
    "nodemon": "^2.0.2",
    "prettier": "^3.2.4",
    "prettier-eslint": "^16.3.0",
    "ts-jest": "^29.1.1",
    "ts-node": "~7.0.0",
    "typescript": "5.2",
    "webpack": "^5.72.0",
    "webpack-bundle-analyzer": "^4.10.1"
  },
  "overrides": {
    "ng2-file-upload": {
      "@angular/common": "$@angular/common",
      "@angular/core": "$@angular/core"
    }
  }
}