import { HttpService } from '@app/core';
import { Injectable } from '@angular/core';
import { SchedulePensionsJobRequest } from '@app/management/models/requests/schedule-pension-job-request';
import { ScheduleRebateCalculationJobRequest } from '@app/management/models/requests/schedule-rebate-calculation-job-request';

@Injectable({ providedIn: 'root' })
export class ManagementService {
  constructor(private http: HttpService) {}

  schedulePensionMonthEnd = (request: SchedulePensionsJobRequest) =>
    this.http.patch(`pensions/pensions/job`, request);

  scheduleRebateCalculation = (request: ScheduleRebateCalculationJobRequest) =>
    this.http.patch(`underwriting/policyrebates/job`, request);

  scheduleReinsuranceEventFieldSync = () =>
    this.http.patch(`finance/reinsurance-claims/job`, {});
}
