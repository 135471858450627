import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ManagementService } from '@app/management/services/management.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApiError } from '@app/core/models/ErrorResponse';
import { of } from 'rxjs';
import { ToastService } from '@app/core/services/toast.service';
import * as actions from '../state/management.actions';

@Injectable()
export class ManagementEffects {
  constructor(
    private actions$: Actions,
    private managementService: ManagementService,
    private toastService: ToastService,
  ) {}

  schedulePensionJob = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.schedulePensionJob),
      switchMap(({ request }) =>
        this.managementService.schedulePensionMonthEnd(request).pipe(
          switchMap(() => [actions.schedulePensionJobSuccess()]),
          tap(() => {
            this.toastService.showSuccess(`Pension job scheduled successfully`);
          }),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `An error occurred while scheduling the selected job - ${friendlyMessage}`,
            );
            return of(
              actions.schedulePensionJobFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  scheduleRebateCalculation = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.scheduleRebateCalculation),
      switchMap(({ request }) =>
        this.managementService.scheduleRebateCalculation(request).pipe(
          switchMap(() => [actions.scheduleRebateCalculationSuccess()]),
          tap(() => {
            this.toastService.showSuccess(
              `Rebate calculation scheduled successfully`,
            );
          }),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `An error occurred while scheduling the selected job - ${friendlyMessage}`,
            );
            return of(
              actions.scheduleRebateCalculationFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );

  scheduleReinsuranceEventFieldSync = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.scheduleReinsuranceEventFieldSync),
      switchMap(() =>
        this.managementService.scheduleReinsuranceEventFieldSync().pipe(
          switchMap(() => [actions.scheduleReinsuranceEventFieldSyncSuccess()]),
          tap(() => {
            this.toastService.showSuccess(
              `Event field sync scheduled successfully`,
            );
          }),
          catchError(({ friendlyMessage }: ApiError) => {
            this.toastService.showError(
              `An error occurred while scheduling the selected job - ${friendlyMessage}`,
            );
            return of(
              actions.scheduleReinsuranceEventFieldSyncFailure({
                error: friendlyMessage,
              }),
            );
          }),
        ),
      ),
    ),
  );
}
