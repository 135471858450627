import { Component, OnInit } from '@angular/core';
import { AuthenticationFacade } from '@app/authentication/state/authentication.facade';
import { AppInsightsService } from '@app/core/services/app-insights.service';
import { SpeedTestService, SpeedTestSettingsModel } from 'ng-speed-test';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  name: string;
  username: string;
  jobTitle: string;

  appVersion = packageInfo?.version;
  displayNotifications = this.appConfig.config.features.displayNotifications;
  deploymentEnvironment = this.appConfig.config.deploymentEnvironment;
  buildIndicatorUrl = this.appConfig.config.buildIndicatorUrl;
  unitTestIndicatorUrl = this.appConfig.config.unitTestIndicatorUrl;
  currentSpeed: number = null;
  displaySpeed: string;
  interval: any;
  speedSettings: SpeedTestSettingsModel = {
    file: {
      path: '/assets/speedtest/speedtest-500kb.jpg',
      shouldBustCache: true,
      size: 408949,
    },
  };
  readonly internetSpeedSettings = this.appConfig.config.features.internetSpeed;

  constructor(
    private authenticationFacade: AuthenticationFacade,
    private speedTestService: SpeedTestService,
    private appInsights: AppInsightsService,
    private appConfig: AppConfigService,
  ) {}

  ngOnInit() {
    this.authenticationFacade.user$.subscribe((user) => {
      if (user) {
        this.username = user.username;
        this.name = user.fullName;
        this.jobTitle = user.jobTitle;
      }
    });

    if (this.internetSpeedSettings.enabled) {
      this.speedTestService
        .getMbps(this.speedSettings)
        .subscribe(this.setSpeed);

      this.startSpeedWatcher(this.internetSpeedSettings.intervalMilliseconds);
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.authenticationFacade.logout();
  }

  startSpeedWatcher(intervalMilliseconds: number) {
    this.interval = setInterval(
      () =>
        this.speedTestService
          .getMbps(this.speedSettings)
          .subscribe(this.setSpeed),
      intervalMilliseconds,
    );
  }

  setSpeed = (speed: number) => {
    this.displaySpeed = speed.toFixed(2);
    this.currentSpeed = speed;

    if (!this.appConfig.config.applicationInsights.disableTelemetry) {
      this.appInsights.log('User speed', {
        speed,
        displaySpeed: this.displaySpeed,
        username: this.username,
        localTimestamp: new Date(),
      });
    }
  };

  getSpeedClass() {
    if (
      this.currentSpeed <
      this.appConfig.config.features.internetSpeed.slowInternetThreshold
    ) {
      return 'danger';
    }

    if (
      this.currentSpeed <
      this.appConfig.config.features.internetSpeed.averageInternetThreshold
    ) {
      return 'warning';
    }

    return 'success';
  }
  get shouldDisplayDeploymentEnvironment(): boolean {
    return this.deploymentEnvironment !== 'PROD';
  }
}
